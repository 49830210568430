import {Link, usePage} from '@inertiajs/react';
import {truncate} from 'lodash';

export default function ({
    element,
    queryObject,
    showBorder = false,
    type = 'course',
}: any) {
    const {
        ziggy: {query},
        currentLang,
    }: any = usePage().props;

    return (
        <Link
            href={route(`${currentLang}.frontend.${type}.index`, {
                ...queryObject,
            })}
            className={`flex flex-col justify-center items-center  ${query?.category_id == element.id ? `bg-theme-100 rounded-3xl` : `hover:bg-gray-100`} ${showBorder ? `h-36 w-1/2 md:w-1/4 border-x border-gray-100 space-y-6` : `h-24 w-24 space-y-2`} hover:text-theme-400     transition-all duration-300 ease-in-out hover:scale-95 hover:rounded-3xl`}
        >
            <img
                className=" h-8 w-auto rounded-md z-0"
                src={element.thumb}
                alt={element.name}
            />
            <h3
                className={`mt-0 ${showBorder ? `text-sm` : `text-xxs`} font-semibold leading-7 tracking-tight text-gray-500 truncate capitalize`}
            >
                {truncate(`${element.name} ${element.stage?.name ?? ``}`, {
                    length: 25,
                })}
            </h3>
        </Link>
    );
}
